import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {UserInfoService} from "../../services/user-info.service";
import {Observable} from "rxjs";
import {SessionContext} from "../../models/session-context.model";

/**
 * RequestBlockingInterceptor intercepts HTTP requests and blocks certain requests if the session is not
 * fully initiated in server and some request are still not allowed.
 *
 * @class RequestBlockingInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class RequestBlockingInterceptor implements HttpInterceptor {

  constructor(
    private userInfoService: UserInfoService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const sessionContext: SessionContext = this.userInfoService.getSessionContext();
    // Block certain requests if the user is not authorized
    if (!sessionContext?.isAuthorizedUser && this.isProtectedRequest(req)) {
      return new Observable();
    }
    return next.handle(req);
  }


  /**
   * Determines if the given request is a protected request.
   * A protected request is one that does not include any endpoint from the unprotectedEndpoints list
   * and includes '/rest/general-request' in its URL.
   *
   * @param {HttpRequest<any>} req - The request to check.
   * @return {boolean} - True if the request is protected, false otherwise.
   */
  private isProtectedRequest(req: HttpRequest<any>): boolean {
    const unprotectedEndpoints = [
      '/rest/general-request/accept-terms',
    ];

    // If the request's URL includes anything in the unprotectedEndpoints list, it's not a protected request
    if (unprotectedEndpoints.some(endpoint => req.url.includes(endpoint))) {
      return false;
    }

    // Otherwise, consider it protected if it includes '/rest/general-request'
    return req.url.includes('/rest/general-request');
  }
}

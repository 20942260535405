import { Injectable } from '@angular/core';
import { CanActivate, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserInfoService } from '../../services/user-info.service';
import { ConsentService } from "../../services/consent.service";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  isAuthenticated: boolean;
  isNew: boolean;

  constructor(
    public authService: AuthService,
    public router: Router,
    public userService: UserInfoService,
    private consentService: ConsentService,
  ) {
    this.authService.isLoggedIn().subscribe((value) => {
      this.isAuthenticated = value;
    });
    this.authService.isNew$.subscribe((value) => {
      this.isNew = value;
    });
    this.initializeMainRouteConsentCheck();
  }

  canActivate(): boolean {
    if (!this.isAuthenticated || this.isNew) {
      this.router.navigate(["/welcome"]);
      return false;
    }
    return true;
  }

  /**
   * Initializes the main route consent check.
   * method subscribes to the router events and checks if the current route is the main route.
   * If the register is using consent service and user has not given consent, it checks for the consents from "Nõusolekuteenus".
   *
   * @return {void}
   */
  private initializeMainRouteConsentCheck(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      filter(_ => this.isMainRoute(this.router.url))
    ).subscribe(async _ => {
      let registerIsUsingConsentService = this.userService.getSessionContext().activeConsentService;
      let insuranceConsentReference = Boolean(this.userService.sessionContext.getValue().termsOutput.insuranceConsentReference)
      this.consentService.updateConsent(!!insuranceConsentReference);
      if (registerIsUsingConsentService && !this.consentService.userHasGivenConsent) {
        await this.consentService.checkIfHasConsents();
      }
    });
  }

  private isMainRoute(url: string): boolean {
    return url === '/';
  }
}

import {Component, HostListener, Inject, NgZone, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subscription} from 'rxjs';
import {PwaUpdateService} from './pwa/pwa-update-service.service';
import {AddHomescreenService} from './pwa/add-homescreen.service';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {GeneralRequestService} from './services/general-request.service';
import {NotificationService} from './features/message/notification.service';
import {SwUpdate} from '@angular/service-worker';
import {NavigationEnd, Router, RouterModule, RouterOutlet} from '@angular/router';
import {filter} from 'rxjs/operators';
import {DateAdapter} from '@angular/material/core';
import {environment} from 'src/environments/environment';
import {DOCUMENT, NgClass} from '@angular/common';
import {AuthService} from './services/auth.service';
import {ModalService} from './services/modal.service';
import {PageTitleService} from './services/page-title.service';
import {UrlService} from './services/url.service';
import {AccessibilityService} from "./shared/accessibility/accessibility.service";
import {AppConfigService} from "./services/app-config.service";
import {FabButtonComponent} from './shared/fab-button-service/fab-button.component';
import {GlobalHeaderComponent} from './features/global-header/global-header.component';
import {SkipLinkComponent} from './shared/skip-link/skip-link.component';
import {IconService} from "./services/icon.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  standalone: true,
  imports: [
    SkipLinkComponent,
    NgClass,
    GlobalHeaderComponent,
    FabButtonComponent,
    RouterOutlet,
    RouterModule
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn$: Observable<boolean>;
  isNew: boolean;
  showSkipLink: boolean;
  showSkipLinkSub: Subscription;

  title = "egp";
  idleState = "Not started.";

  fetchNewMessagesInterval;
  previousUrl: string;
  currentUrl: string;

  constructor(
    public translate: TranslateService,
    public authService: AuthService,
    public addHomescreenService: AddHomescreenService,
    private pwaUpdate: PwaUpdateService,
    private idle: Idle,
    private modalService: ModalService,
    private accessibility: AccessibilityService,
    private generalRequestService: GeneralRequestService,
    private notificationService: NotificationService,
    private ngZone: NgZone,
    private swUpdate: SwUpdate,
    private router: Router,
    private renderer: Renderer2,
    private pageTitleService: PageTitleService,
    private urlService: UrlService,
    private dateAdapter: DateAdapter<Date>,
    private configService: AppConfigService,
    private iconService: IconService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.iconService.registerIcons();
    this.configService.loadConfig();
    this.isLoggedIn$ = this.authService.isLoggedIn();
    this.authService.isNew.subscribe((value) => {
      this.isNew = value;
    });
    translate.setDefaultLang("ee");

    // Disabled: https://epak.atlassian.net/browse/EPAK-606
    // if (!this.cookieService.get("cookies_accepted")) {
    //   this.modalService.showCookieModal();
    // }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.urlService.setPreviousUrl(this.currentUrl);
        this.currentUrl = event.url;
        this.urlService.setCurrentUrl(this.currentUrl);
        this.resetFocus();
      });

    this.pageTitleService.initialize();

    this.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn && !this.isNew) {
        this.resetIdle();
        this.setUpMessageFetching();
      } else {
        clearInterval(this.fetchNewMessagesInterval);
        idle.stop();
      }
    });

    idle.onIdleEnd.subscribe(() => {
      this.resetIdle();
    });

    idle.onIdleStart.subscribe(() => {
      this.modalService.showIdleModal(this.idleState);
    });

    idle.onTimeout.subscribe(() => {
      idle.stop();
      clearInterval(this.fetchNewMessagesInterval);
      this.authService.clearSession();
    });
  }

  resetIdle() {
    this.idle.stop();
    this.idle.setIdle(environment.session.sessionLength);
    this.idle.setTimeout(environment.session.idleLength);
    // sessionLength sets a timeout period. after that, when <idleLength> seconds of inactivity have past, the user will be considered timed out.
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.watch();
  }

  setUpMessageFetching() {
    this.generalRequestService.fetchMeetings();
    this.notificationService.fetchNotificationsOnInit();
    this.ngZone.runOutsideAngular(() => {
      this.fetchNewMessagesInterval = setInterval(() => {
        this.ngZone.run(() => {
          this.generalRequestService.fetchMeetings();
          this.notificationService.fetchNewNotifications();
        });
      }, (environment.session.messageFetchFrequency * 1000));
    });
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(hasUpdateAvailable => {
        if (hasUpdateAvailable) {
          this.swUpdate.activateUpdate().then(() => {
            document.location.reload();
          });
        }
      })
    }
    this.setLang();
    this.showSkipLinkSub = this.accessibility.showSkipLink$.subscribe(value => {
      this.showSkipLink = value;
    });
  }

  resetFocus() {
    // Set focus to an element at the top of the page for accessibility
    const topElement = this.renderer.selectRootElement('#top-of-page');
    if (topElement) {
      topElement.focus();
    }
  }

  setLang() {
    this.subscribeToLangChanges();
    if (localStorage.getItem("lang")) {
      this.translate.use(localStorage.getItem("lang"));
    } else {
      this.translate.use(this.translate.defaultLang);
      localStorage.setItem("lang", this.translate.currentLang);
    }
  }

  subscribeToLangChanges() {
    this.translate.onLangChange.subscribe((changeEvent) => {
      localStorage.setItem("lang", changeEvent.lang);
      const calendarLocale =
        changeEvent.lang === "ee" ? "et" : changeEvent.lang;
      sessionStorage.setItem("lang", calendarLocale);
      this.dateAdapter.setLocale(calendarLocale);

      // Update the lang attribute of the <html> element
      this.document.documentElement.lang = changeEvent.lang;
    });
  }

  ngOnDestroy() {
    this.showSkipLinkSub.unsubscribe();
  }

  @HostListener("window:beforeinstallprompt", ["$event"])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.addHomescreenService.storePrompt(e);
  }
}
